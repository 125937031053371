<template>
  <div>
    <Table
      resource="Product"
      :actions="actions"
      :table-actions="tableActions"
      :items="$store.getters['product/tableData']"
      :detail="$store.getters['product/detailData']"
      :fields="$store.getters['product/tableFields']"
      :pagination="$store.state.product.tablePagination"
      :keyword="$store.state.product.tableKeyword"
      :loading="$store.state.product.loadingTable"
      :filter="$store.state.product.tableFilter"
      :sort="$store.state.product.tableSort"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @delete="deleteData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
      @paginate="paginate"
      @search="search"
    >
      <template #action>
        <b-button
          block
          variant="outline-dark"
          class="pl-1 border-default d-flex align-items-center"
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          <span>Ekspor Data</span>
        </b-button>
      </template>
    </Table>
    <Modal
      :show-modal.sync="showClearCacheModal"
      title="Sync daftar produk"
      ok-title="Ya, yakin"
      :ok-action="clearCache"
      :loading-submit="$store.state.product.loadingSubmit"
    >
      Daftar produk di <b-link
        target="_blank"
        href="https://miniapoly.com/"
      >
        miniapoly.com
      </b-link> akan disinkronisasi dengan data terbaru. Daftar produk mungkin dimuat lebih lama. Apakah Anda yakin?
    </Modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import { BButton, BLink } from 'bootstrap-vue'
import Modal from '@/layouts/components/Modal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Modal,
    BButton,
    Table,
    BLink,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'created_at', direction: 'desc', label: 'Terbaru', default: true,
        },
        { key: 'created_at', direction: 'asc', label: 'Terlama' },
      ],

      // table action
      tableActions: [
        {
          label: 'Tambah dari eksternal',
          event: () => {
            this.$router.push('/scrape-product')
          },
          visibility: {
            callback: () => this.canAccess('manage', 'Product'),
          },
        },
        {
          label: 'Sync daftar produk',
          event: () => {
            this.confirmClearCache()
          },
          visibility: {
            callback: () => this.canAccess('manage', 'Product'),
          },
        },
      ],

      // row actions
      actions: [
        'create',
        'view',
        'update',
        'delete',
      ],

      // cache
      showClearCacheModal: false,
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('product/getTableData')
    },
    loadDetail(id) {
      return this.$store.dispatch('product/getDetail', id)
    },
    deleteData(id, callback) {
      return this.$store.dispatch('product/delete', id)
        .then(callback)
    },
    resetDetail() {
      this.$store.commit('product/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('product/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('product/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('product/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('product/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('product/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('product/SET_TABLE_KEYWORD', val)
    },
    confirmClearCache() {
      this.showClearCacheModal = true
    },
    clearCache() {
      this.$store.dispatch('product/clearCache')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: 'Daftar produk berhasil diperbarui.',
              variant: 'success',
            },
          })
        }).finally(() => {
          this.showClearCacheModal = false
        })
    },
  },
}
</script>
